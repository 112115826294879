import SA from 'scroll-animations-js';
import $ from 'jquery';
SA.init();

$(".menu-sand").click(function () {
    $(".menu-mobile").toggleClass("act");
    return false;
});

$(".menu-mobile a").click(function () {
    $(".menu-mobile").toggleClass("act");
})